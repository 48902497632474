import { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";

import "./App.css";
import useContract from "./hooks/useContract";
import useWallet from "./hooks/useWallet";
import abi from "./assets/abi.json";

import logo from "./assets/images/logo.png";
import SlideOut from "./components/slideOut";
import SaleDialog from "./components/SaleDialog";

function toFixed(num, fixed) {
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re)[0];
}

const App = () => {
  const [count, setCount] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);
  const { connect, address, slicedAddress, balance, web3, gas } = useWallet();
  const {
    mint,
    preMint,
    connection,
    price,
    maxTx,
    saleActive,
    presaleActive,
    totalSupply,
    txHash,
    minting,
    minted,
    mintError,
  } = useContract(address, abi);

  useEffect(() => {
    if (txHash) {
      setModalOpen(true);
      console.log(modalOpen);
    }
  }, [txHash, modalOpen]);

  useEffect(() => {
    if (minted || mintError) {
      setModalOpen(false);
    }
  }, [minted, mintError]);

  const roundedBal =
    balance && `${toFixed(+web3.utils.fromWei(balance, "ether"), 2)} ETH`;

  const increment = () => count < maxTx && setCount(count + 1);
  const decrement = () => count > 1 && setCount(count - 1);

  return (
    <>
      <SaleDialog isOpen={modalOpen} setIsOpen={setModalOpen} tx={txHash} />
      <div className="flex flex-col h-screen w-full text-xs sm:text-base">
        <div className="py-5 px-3 flex justify-between">
          <img className="h-9" alt="logo" src={logo} />
          <SlideOut direction="left" content={roundedBal}>
            {address ? (
              <span>{slicedAddress}</span>
            ) : (
              <button onClick={connect}>Connect Wallet</button>
            )}
          </SlideOut>
        </div>
        <div className="flex px-2 justify-center items-center h-2/3">
          <Transition
            show={!!address}
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="card bg-gray-900 p-4 rounded-2xl space-y-3 text-white">
              <h1 className="font-semibold">
                Moose Vs. Hunger {saleActive ? "Public " : "Pre Sale "}
                Mint
              </h1>
              <div className="flex flex-col space-y-2">
                <div className="p-4 bg-gray-800 rounded-2xl flex justify-between items-center">
                  <div className="flex flex-col space-y-3">
                    <h2 className="text-xl">Total to Mint</h2>
                    <span className="text-gray-400">Balance: {roundedBal}</span>
                  </div>
                  <div className="flex items-center space-x-3">
                    <button
                      onClick={decrement}
                      className="flex p-1 justify-center items-center h-6 w-6 rounded-full bg-yellow-500 text-3xl"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M18 12H6"
                        />
                      </svg>
                    </button>
                    <div className="text-3xl">{count.toString()}</div>
                    <button
                      onClick={increment}
                      className="flex p-1 justify-center items-center h-6 w-6 rounded-full bg-yellow-500 text-3xl"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="flex justify-end">
                  {count.toString()} MOOSE ={" "}
                  {web3 &&
                    web3.utils.fromWei(
                      (count * price).toString(),
                      "ether"
                    )}{" "}
                  ETH
                </div>
                <button
                  disabled={
                    (!presaleActive && !saleActive) ||
                    minting ||
                    price * count > balance
                  }
                  onClick={() =>
                    presaleActive
                      ? preMint(count, address)
                      : mint(count, address)
                  }
                  className="flex justify-center items-center py-4 rounded-2xl text-xl font-semibold bg-yellow-500 disabled:opacity-30 disabled:cursor-not-allowed transition-opacity duration-500 ease-in-out"
                >
                  {!presaleActive && !saleActive
                    ? "Sale Inactive"
                    : price * count > balance
                    ? "Insufficient Funds"
                    : minting
                    ? "Transaction Pending"
                    : "Mint"}
                </button>
              </div>
            </div>
          </Transition>
        </div>
        <div className="flex-grow flex px-3 py-5 justify-between items-end">
          <div className="flex flex-col space-y-2">
            <SlideOut direction="right" content={gas && `${gas} gwei`}>
              <span>Fee per Gas</span>
            </SlideOut>
            <SlideOut direction="right" content={totalSupply}>
              <span>Total Minted</span>
            </SlideOut>
          </div>
          <Transition
            show={!!address}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="flex flex-col space-y-1 px-4 py-2 text-white bg-gray-900 rounded-2xl">
              <div className="flex items-center justify-end space-x-3">
                <span>Connection</span>
                {connection ? (
                  <div className="h-4 w-4 rounded-full bg-green-500">
                    &nbsp;
                  </div>
                ) : (
                  <div className="h-4 w-4 rounded-full bg-red-500">&nbsp;</div>
                )}
              </div>
              <div className="flex items-center justify-end space-x-3">
                <span>Pre Sale</span>
                {presaleActive ? (
                  <div className="h-4 w-4 rounded-full bg-green-500">
                    &nbsp;
                  </div>
                ) : (
                  <div className="h-4 w-4 rounded-full bg-red-500">&nbsp;</div>
                )}
              </div>
              <div className="flex items-center justify-end space-x-3">
                <span>Primary Sale</span>
                {saleActive ? (
                  <div className="h-4 w-4 rounded-full bg-green-500">
                    &nbsp;
                  </div>
                ) : (
                  <div className="h-4 w-4 rounded-full bg-red-500">&nbsp;</div>
                )}
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default App;
