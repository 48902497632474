import "dotenv/config";
import { useState } from "react";
import Web3 from "web3";
import useDidMountEffect from "./useDidMount";

/**
 * Gets contract info
 * @param {addr} address The contract address
 * @param {json} abi JSON abi
 * @returns mint,
    preMint,
    connection,
    price,
    maxTx,
    saleActive,
    presaleActive,
    totalSupply,
    maxSupply,
    txHash,
    loading,
    minting,
    minted,
    mintError,
 */
const useContract = (address, abi) => {
  const CONTRACT_ADDR = process.env.REACT_APP_CONTRACT_ADDRESS;
  const INFURA_ID = process.env.REACT_APP_INFURA_ID;
  const [windowContract, setWindowContract] = useState(null);
  const [infuraContract, setInfuraContract] = useState(null);
  const [connection, setConnection] = useState(false);
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);
  const [maxTx, setMaxTx] = useState(0);
  const [saleActive, setSaleActive] = useState(false);
  const [presaleActive, setPresaleActive] = useState(false);
  const [totalSupply, setTotalSupply] = useState(null);
  const [maxSupply, setMaxSupply] = useState(0);
  const [txHash, setTxHash] = useState(null);
  const [minting, setMinting] = useState(false);
  const [minted, setMinted] = useState(false);
  const [mintError, setMintError] = useState(null);

  // mount
  useDidMountEffect(() => {
    const ww3 = new Web3(window.ethereum);
    const wctx = new ww3.eth.Contract(abi, CONTRACT_ADDR);
    setWindowContract(wctx);

    const provider = new Web3.providers.WebsocketProvider(
      `wss://mainnet.infura.io/ws/v3/${INFURA_ID}`
    );
    const iw3 = new Web3(provider);
    const ictx = new iw3.eth.Contract(abi, CONTRACT_ADDR);
    setInfuraContract(ictx);
  }, [address]);

  // GET
  useDidMountEffect(() => {
    if (!infuraContract) {
      return;
    }
    setLoading(true);
    infuraContract.methods
      .COST()
      .call()
      .then((val) => {
        setPrice(val);
      });
    infuraContract.methods
      .MAX_TX()
      .call()
      .then((val) => {
        setMaxTx(val);
      });
    infuraContract.methods
      .MAX_SUPPLY()
      .call()
      .then((val) => {
        setMaxSupply(val);
        setLoading(false);
      });
    infuraContract.methods
      .saleActive()
      .call()
      .then((val) => {
        setSaleActive(val);
      });
    infuraContract.methods
      .presaleActive()
      .call()
      .then((val) => {
        setPresaleActive(val);
      });
    infuraContract.methods
      .totalSupply()
      .call()
      .then((val) => {
        setTotalSupply(val);
      });
  }, [infuraContract]);

  // Socket
  useDidMountEffect(() => {
    if (!infuraContract) {
      return;
    }
    infuraContract.events
      .allEvents()
      .on("connected", (id) => {
        setConnection(true);
      })
      .on("data", ({ event, returnValues }) => {
        switch (event) {
          case "SaleStateChanged":
            setSaleActive(returnValues.val);
            break;
          case "PresaleStateChanged":
            setPresaleActive(returnValues.val);
            break;
          case "TotalSupplyChanged":
            setTotalSupply(returnValues.val);
            break;
          default:
            break;
        }
      })
      .on("error", (err) => {
        setConnection(false);
      });
  }, [infuraContract]);

  const mintHelper = (func, purchaser, count) => {
    setTxHash(null);
    setMinting(true);
    setMinted(false);
    setMintError(null);
    func
      .send({ from: purchaser, value: price * count })
      .on("transactionHash", (hash) => {
        setTxHash(hash);
      })
      .on("receipt", (receipt) => {
        setMinting(false);
        setMinted(true);
      })
      .on("error", (err) => {
        setMinting(false);
        setMintError(err.message);
        console.warn("Code", err.code);
      });
  };

  /**
   * Mints a specified amount of NFTs (public sale)
   * @param {int} count The number to mint
   * @param {addr} purchaser Wallet address that is buying
   */
  const mint = (count, purchaser) =>
    mintHelper(windowContract.methods.mint(count), purchaser, count);
  /**
   * Mints a specified amount of NFTs (presale)
   * @param {int} count The number to mint
   * @param {addr} purchaser Wallet address that is buying
   */
  const preMint = (count, purchaser) =>
    mintHelper(windowContract.methods.preMint(count), purchaser, count);

  return {
    mint,
    preMint,
    connection,
    price,
    maxTx,
    saleActive,
    presaleActive,
    totalSupply,
    maxSupply,
    txHash,
    loading,
    minting,
    minted,
    mintError,
  };
};

export default useContract;
